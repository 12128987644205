import {Container, Row} from "react-bootstrap";

import React from "react";
import 'aos/dist/aos.css';
import 'lightbox.js-react/dist/index.css'
import {ILLUSTRATION} from "../illustrationConstant";
import ImageDisplay from "./ImageDisplay";

function BandeDessinee() {

    const displayedImages = ILLUSTRATION.filter(value => value.keywords === "bd").sort((a, b) => a.rank - b.rank);

    return (
        <>
            <Container fluid className="main-container">
                <Row className="">
                    <ImageDisplay displayedImages={displayedImages}/>
                </Row>
            </Container>

        </>
    );
}

export default BandeDessinee;
