import {Outlet, useLocation} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";

import {Envelope, Instagram} from "react-bootstrap-icons";

const Layout = () => {
    const location = useLocation();

    const currentPageStyle = (pageName) => {
        return {
            color: location.pathname === pageName ? '#f9b4fd' : 'inherit',
            borderBottom: location.pathname === pageName ? '2px #f9b4fd solid' : 'none'
        }
    }

    return (
        <>
            <Navbar
                id="main-navigation"
                fixed="top"
                expand="lg"
                collapseOnSelect
                className="bg-body-tertiary p-4">
                <Container fluid>
                    <Navbar.Brand href="/">Roxanne Dionne</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="justify-content-end"  style={{ width: "100%" }}>
                            <Nav.Link className="me-3" href="/" style={currentPageStyle("/")}>Illustrations</Nav.Link>
                            <Nav.Link className="me-3" href="/bandes-dessinees" style={currentPageStyle("/bandes-dessinees")}>Bandes Dessinées</Nav.Link>
                            <Nav.Link className="me-3" href="/apropos" style={currentPageStyle("/apropos")}>À propos</Nav.Link>
                            <Nav.Link className="me-3" href="/contact" style={currentPageStyle("/contact")}>Contact</Nav.Link>
                            <Nav.Link href="https://www.instagram.com/dramaqueendesign/" target="_blank" rel="noreferrer">
                                <Instagram />
                            </Nav.Link>
                            <Nav.Link href="mailto:roxannedionne.illustration@gmail.com" target="_blank" rel="noreferrer">
                                <Envelope />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Outlet />

            <Navbar className="p-4">
                <Container fluid className="p-3">
                    <p className="m-0">© {new Date().getFullYear()} tous droits réservés.</p>
                    <div className="justify-content-end">
                        <div className="ml-auto navbar-nav">
                            <a className="nav-link" href="https://www.instagram.com/dramaqueendesign/" target="_blank" rel="noreferrer">
                                <Instagram />
                            </a>
                            <a className="nav-link" href="mailto:roxannedionne.illustration@gmail.com" target="_blank" rel="noreferrer">
                                <Envelope />
                            </a>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </>
    )
};

export default Layout;