import {Col, Container, Row} from "react-bootstrap";

import covid from "../medias/Covid_19_test.jpg";

function ContactView() {

    return (
        <>
            <Container id="contact-page" className="main-container">
                <Row style={{marginBottom: '16rem'}}>
                    <Col md={6}>
                        <h2 className="mt-5 mb-4">Contact</h2>
                        <p className="text">Vous avez un projet en tête et pensez former une bonne équipe avec elle?
                            Écrivez-lui à l’adresse <a className="link-mail" href="mailto:roxannedionne.illustration@gmail.com"><strong>roxannedionne.illustration@gmail.com</strong></a> !</p>
                    </Col>
                    <Col md={6}>
                        <div className="img-container">
                            <img src={covid} alt="covid"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactView;
