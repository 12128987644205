import {Col, Container, Row} from "react-bootstrap";

import covid from "../medias/Covid_19_test.jpg"


function NoPage() {
    return (
        <>
            <Container className="mt-5">
                <Row >
                    <Col md={12}>
                        <div className="mt-5">
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                left: '50%',
                            }}>
                                <h1 style={{
                                    position: 'relative',
                                    left: '-50%',
                                    color:"white"
                                }}>404 Page Not Found</h1>
                            </div>
                            <div className="d-flex justify-content-center">
                                <img id="notfound" src={covid} alt="covid"/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NoPage;
