import {Card} from "react-bootstrap";

import React, {useCallback, useEffect, useState} from "react";
import 'aos/dist/aos.css';
import AOS from 'aos';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import 'lightbox.js-react/dist/index.css'
import ImageViewer from 'react-simple-image-viewer';

function ImageDisplay(props) {

    useEffect(() => {
        AOS.init({once: false});
        window.addEventListener('load', AOS.refresh);
    }, []);

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const imagesSources = props.displayedImages.map(ill => ill.src);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            {isViewerOpen && (
                <ImageViewer
                    src={imagesSources}
                    currentIndex={currentImage}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.7)"
                    }}
                />
            )}
            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 650: 2, 900: 3, 1200: 4}}>
                <Masonry gutter="1rem">
                    {props.displayedImages.map((images, index) => {
                        return (
                            <div
                                key={images.id}
                                data-aos="zoom-in">
                                <Card
                                    className="card"
                                    style={{borderColor: "#fbd2ff"}}>
                                    <Card.Img
                                        className="rounded"
                                        alt={images.title}
                                        style={{cursor:"pointer"}}
                                        onClick={() => openImageViewer(index)}
                                        src={images.src}/>
                                </Card>
                            </div>
                        );
                    })}
                </Masonry>
            </ResponsiveMasonry>
        </>
    );
}

export default ImageDisplay;
