import benjamin from "./medias/Benjamin.jpg"
import birthday from "./medias/Birthdaygirl_Impression.jpg"
import blm from "./medias/BlackLivesMatterTaor.jpg"
import carte from "./medias/Carte_Fred.jpg"
import meme from "./medias/Chez_mémé.jpg"
import coiffure from "./medias/Coiffure_portrait_2.jpg"
import coven from "./medias/Coven_RVB.jpg"
import covid from "./medias/Covid_19_test.jpg"
import inktober from "./medias/InktoberToad.jpg"
import koi from "./medias/Koi_Girl_insta_2.jpg"
import papillons from "./medias/Les_Papillons.jpg"
import ecrive from "./medias/Les+écrivements+RVB.jpg"
import limbe from "./medias/Limbes_Vingtaine_Récupéré.jpg"
import luna from "./medias/Luna_Lovegood_Insta.jpg"
import poster from "./medias/Poster_5x7.jpg"
import potion from "./medias/potion.jpg"
import sirene from "./medias/sirene.jpg"
import subway from "./medias/Subway_watercolorRVB.jpg"
import summer from "./medias/summer_vibe.jpg"
import witch from "./medias/Western_Witch.jpg"
import barbie from "./medias/Barbie.jpg"
import hopper from "./medias/Hopper.jpg"
import motel from "./medias/Motel-sign.jpg"

// BD
import comicstrip from "./medias/comicstrip.jpg"
import nina from "./medias/Nina.jpg"
import cover from "./medias/Couverture.jpg"
import page1 from "./medias/Page-1.jpg"
import page2 from "./medias/Page-2.jpg"
import page3 from "./medias/Page-3.jpg"
import page4 from "./medias/Page-4.jpg"
import tiki from "./medias/tiki.jpg"
import train2 from "./medias/train-2.jpg"
import train1 from "./medias/train-1.jpg"
import western1 from "./medias/western.jpg"
import western2 from "./medias/western-2.jpg"



export const ILLUSTRATION = [
    {id: 1, title: "benjamin", src: benjamin, rank: 21, keywords: "illustration"},
    {id: 2, title: "birthday", src: birthday, rank: 2, keywords: "illustration"},
    {id: 3, title: "blm", src: blm, rank: 3, keywords: "illustration"},
    {id: 4, title: "carte", src: carte, rank: 4, keywords: "illustration"},
    {id: 5, title: "meme", src: meme, rank: 5, keywords: "illustration"},
    {id: 6, title: "coiffure", src: coiffure, rank: 6, keywords: "illustration"},
    {id: 7, title: "coven", src: coven, rank: 7, keywords: "illustration"},
    {id: 8, title: "covid", src: covid, rank: 8, keywords: "illustration"},
    {id: 9, title: "inktober", src: inktober, rank: 9, keywords: "illustration"},
    {id: 10, title: "koi", src: koi, rank: 3, keywords: "illustration"},
    {id: 11, title: "papillons", src: papillons, rank: 11, keywords: "illustration"},
    {id: 12, title: "ecrive", src: ecrive, rank: 12, keywords: "illustration"},
    {id: 13, title: "limbe", src: limbe, rank: 13, keywords: "illustration"},
    {id: 14, title: "luna", src: luna, rank: 14, keywords: "illustration"},
    {id: 15, title: "poster", src: poster, rank: 15, keywords: "illustration"},
    {id: 16, title: "potion", src: potion, rank: 16, keywords: "illustration"},
    {id: 17, title: "sirene", src: sirene, rank: 5, keywords: "illustration"},
    {id: 18, title: "subway", src: subway, rank: 18, keywords: "illustration"},
    {id: 19, title: "summer", src: summer, rank: 19, keywords: "illustration"},
    {id: 20, title: "witch", src: witch, rank: 7, keywords: "illustration"},
    {id: 21, title: "barbie", src: barbie, rank: 1, keywords: "illustration"},
    {id: 22, title: "hopper", src: hopper, rank: 2, keywords: "illustration"},
    {id: 23, title: "motel", src: motel, rank: 23, keywords: "illustration"},
    {id: 24, title: "comicstrip", src: comicstrip, rank: 24, keywords: "bd"},
    {id: 25, title: "nina", src: nina, rank: 25, keywords: "bd"},
    {id: 26, title: "cover", src: cover, rank: 25, keywords: "bd"},
    {id: 27, title: "page1", src: page1, rank: 26, keywords: "bd"},
    {id: 28, title: "page2", src: page2, rank: 27, keywords: "bd"},
    {id: 29, title: "page3", src: page3, rank: 28, keywords: "bd"},
    {id: 30, title: "page4", src: page4, rank: 29, keywords: "bd"},
    {id: 31, title: "tiki", src: tiki, rank: 30, keywords: "bd"},
    {id: 32, title: "train2", src: train2, rank: 31, keywords: "bd"},
    {id: 33, title: "train1", src: train1, rank: 32, keywords: "bd"},
    {id: 34, title: "western1", src: western1, rank: 33, keywords: "bd"},
    {id: 35, title: "western2", src: western2, rank: 34, keywords: "bd"},
]
