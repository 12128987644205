import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./view/Home";
import Layout from "./view/Layout";
import AproposView from "./view/AproposView";
import ContactView from "./view/ContactView";
import NoPage from "./view/NoPage";
import BandeDessinee from "./view/BandeDessinee";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="bandes-dessinees" element={<BandeDessinee/>}/>
                    <Route path="apropos" element={<AproposView/>}/>
                    <Route path="contact" element={<ContactView/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
