import {Col, Container, Row} from "react-bootstrap";

import avatar from "../medias/avatar.png"

function AproposView() {
    return (
        <>
            <Container id="apropos-page" className="main-container">
                <Row>
                    <Col md={6}>
                        <h2 className="mt-5 mb-4">À Propos</h2>
                        <p className="text">Roxanne est une illustratrice freelance Montréalaise qui se spécialise dans l'art numérique et l'art de suranalyser ce qui se passe autour d'elle. Elle  crée des personnages un peu gaffeurs et marginaux et leur fait traverser des épreuves qu'elle leur invente elle-même pour leur rendre la vie dure. Elle se nourrie essentiellement de romans graphiques et de café, est fan de randonnée et affectionne particulièrement les grands-mères et les trucs kitsch. </p>
                        <p className="text">Elle travaille présentement sur sa première bande dessinée portant sur l'entrée dans l'âge adulte, l'anxiété et le deuil.</p>
                    </Col>
                    <Col md={6}>
                        <div className="avatar-container">
                            <img src={avatar} alt="avatar"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AproposView;
